import ReactDOM from "react-dom";
import "./styles/index.css";
import React from "react";

(async () => {
    await fetch("/configuration.json")
        .then((res) => res.json())
        .then((config) => window.configuration = config)
        .catch((e) => null);
    const {apiLoaded} = await import("./lib/ApiClient");
    const App = await import("./App");
    await apiLoaded.catch(() => void 0);
    ReactDOM.render(
        <React.StrictMode>
                <App.default/>
        </React.StrictMode>,
        document.getElementById("root")
    );
})();
